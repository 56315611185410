import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { t } from "i18next";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";
import ContentLayout from "../../components/shared-content-layout";

const SubjectClassIndexPage = observer(() => {
  const { rootStore, academicStore, calendarStore, teacherStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentSubject, setCurrentSubject] = useState<number>(0);
  const [targetSubjectClass, setTargetSubjectClass] = useState<any>();
  const [classCode, setClassCode] = useState<string>("");
  const [identifier, setIdentifier] = useState<string>("");
  const [semesterId, setSemesterId] = useState<string>("0");
  const [subjectId, setSubjectId] = useState<string>("0");
  const [staffId, setStaffId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);
  const [searchSubjectKey, setSearchSubjectKey] = useState<string>("");
  const onChangeSemester = (event: SelectChangeEvent) => {
    setSemesterId(event.target.value as string);
  };
  const onChangeSubject = (event: SelectChangeEvent) => {
    setSubjectId(event.target.value as string);
  };
  const onChangeStaff = (event: SelectChangeEvent) => {
    setStaffId(event.target.value as string);
  };

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0)
        .then((res) => onToggleSubjectClassList(res.data[0].id));
    } else {
      onToggleSubjectClassList(academicStore.subjectList.data[0].id)
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) != calendarStore.calendarSettingList.data.length) {
      calendarStore.getCalendarSettingList(0, 0);
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) != teacherStore.teacherProfileList.data.length) {
      teacherStore.getTeacherProfileList(0, 0);
    }
  }, []);

  const onToggleSubjectClassList = (id: number, skip?: number) => {
    academicStore.getSubjectClassListBySubjectId(id, 0, 0);
    setCurrentSubject(id);
  }

  const onActionSubjectClass = () => {
    let req: any = {
      id: 0,
      classCode,
      classIdentifier: identifier,
      semesterId,
      subjectId,
      staffId,
    }
    if (targetSubjectClass) {
      req.id = targetSubjectClass.id;
      academicStore.updateSubjectClass(req).then(() => {
        rootStore.notify(t('SUBJECT_CLASS') + t('UPDATED_B'), 'success');
        onToggleSubjectClassList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      academicStore.createSubjectClass(req).then(() => {
        rootStore.notify(t('SUBJECT_CLASS') + t('CREATED_B'), 'success');
        onToggleSubjectClassList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = academicStore.subjectClassList.data.find(s => s.id == id);
      setTargetSubjectClass(target);
      setClassCode(target.classCode);
      setIdentifier(target.classIdentifier);
      setSemesterId(target.semesterId);
      setSubjectId(target.subjectId);
      setStaffId(target.staffId);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetSubjectClass(null);
    setClassCode("");
    setIdentifier("");
    setSemesterId("0");
    setSubjectId("0");
    setStaffId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = academicStore.subjectClassList.data.find(s => s.id == id);
    setTargetSubjectClass(target);
    setShowDeleteModal(true);
  }


  const onChangeSubjectAutoComplete = (subjectCode: string) => {
    if (academicStore.subjectList.data.length > 0) {
      let subject = academicStore.subjectList.data.find(s => subjectCode === s.subjectCode);
      console.log(subject, subjectCode);
      if (subject) setSubjectId(subject.id);
    }
  }

  return (
    <ContentLayout
      pageName={t('SUBJECT_CLASS')}
      pageHeading={t('SUBJECT_CLASS')}
      breadCrumb={[
        { label: t('SUBJECT') + t('SETTING_B'), to: "/subject-class-module" },
        { label: t('OVERVIEW'), to: "" }
      ]}
      buttonLabel={t('ADD_A') + t('SUBJECT_CLASS')}
      onClickAction={() => handleShowModal()}
    >
      <div className="mb-4" style={ITEM_PERFECT_INLINED}>
        <TextField
          value={searchSubjectKey}
          label={t('SEARCH_SUBJECT')}
          variant="outlined"
          sx={{ width: 300, background: 'white', marginRight: 2 }}
          onChange={(value) => setSearchSubjectKey(value.target.value)}
          size="small"
        />
        <Typography>{t('SUBJECT_COUNT')}: {academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
          sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "").length}</Typography>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="max-h-96 overflow-auto">
          {
            academicStore.loadingSubject ? <>
              <div className="text-center my-4"><Skeleton /></div>
              <div className="text-center my-4"><Skeleton /></div>
              <div className="text-center my-4"><Skeleton /></div>
            </> :
              academicStore.subjectList.data.length > 0 &&
              academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
                sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "")
                .map((sub: any, index: number) => (
                  <Box key={`subject_select_${index}`}>
                    <button
                      type="button"
                      className={`${currentSubject == sub.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                      onClick={() => onToggleSubjectClassList(sub.id)}
                    >
                      {sub.subjectCode}
                    </button>
                  </Box>
                ))
          }
        </div>
        <div className="col-span-3 max-h-96 overflow-auto">
          <Typography variant="subtitle2" marginBottom={1}>{t('SUBJECT') + t('DESCRIPTION_B')}: {currentSubject != 0 && academicStore.subjectList.data.find(s => s.id == currentSubject).subjectDescription}</Typography>
          <VmTable
            loading={academicStore.loading}
            page={0}
            paginationCount={academicStore.subjectClassList.totalCount == 0 ? 1 : academicStore.subjectClassList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setPage(pageNumber);
              onToggleSubjectClassList(currentSubject, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('CLASS_CODE'), t('CLASS_IDENTIFIER'), t('ACTION')]}
            tbody={academicStore.subjectClassList.data.length > 0 &&
              academicStore.subjectClassList.data.map((sc: any, index: number) => (
                <>
                  <Box sx={{ marginY: 1 }} />
                  <tr key={`subject_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{sc.classCode}</td>
                    <td className={TD_NORMAL}>{sc.classIdentifier}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowModal(sc.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(sc.id)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box sx={{ marginY: 1 }} />
                </>
              ))}
          />
        </div>
      </div>

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetSubjectClass ? t('UPDATE_A') + t('SUBJECT_CLASS') : t('ADD_A') + t('SUBJECT_CLASS')}
        buttonLabel={targetSubjectClass ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetSubjectClass ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionSubjectClass}
        disabledCondition={calendarStore.calendarSettingList.length < 1 || academicStore.subjectList.length < 1 ||
          semesterId == "0" || classCode === "" || identifier === ""}
      >
        <TextField
          value={classCode}
          label={t('CLASS_CODE')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setClassCode(value.target.value)}
        />
        {/* <FormControl fullWidth>
          <InputLabel>{t('SUBJECT')}</InputLabel>
          {academicStore.subjectList.data.length > 0 &&
            <Select
              label={t('SUBJECT')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={subjectId}
              onChange={onChangeSubject}
              disabled={targetSubjectClass}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.subjectList.data.map((subject: any, index: number) => (
                <MenuItem key={`subject_select_${index}`} value={subject.id}>
                  {subject.subjectCode}
                </MenuItem>
              ))}
            </Select>}
        </FormControl> */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={academicStore.subjectList.data.find(s => s.id == subjectId) ? academicStore.subjectList.data.find(s => s.id == subjectId).subjectCode : ""}
          options={academicStore.subjectList.data.map((s) => ({ label: s.subjectCode, id: s.id }))}
          sx={{ width: '100%',marginBottom: 2 }}
          onInputChange={(event, newInputValue) => onChangeSubjectAutoComplete(newInputValue)}
          renderInput={(params) =>
            <TextField  {...params} label={t('SUBJECT')} />}
        />
        <FormControl fullWidth>
          <InputLabel>{t('SEMESTER')}</InputLabel>
          {
            calendarStore.calendarSettingList.data.length > 0 &&
            <Select
              label="Semester"
              sx={{ width: '100%', marginBottom: 2 }}
              value={semesterId}
              onChange={onChangeSemester}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {calendarStore.calendarSettingList.data.map((cYear: any) => (
                cYear.schoolSemesters.map((cSemester: any, index: number) => (
                  <MenuItem key={`semester_select_${index}`} value={cSemester.id}>
                    {cYear.yearDescription + " S" + cSemester.semesterInt}
                  </MenuItem>
                ))
              ))}
            </Select>
          }
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t('TEACHER')}</InputLabel>
          {
            teacherStore.teacherProfileList.data.length > 0 &&
            <Select
              label={t('TEACHER')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={staffId}
              onChange={onChangeStaff}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {teacherStore.teacherProfileList.data.map((teacher: any, index: number) => (
                <MenuItem key={`teacher_select_${index}`} value={teacher.id}>
                  {teacher.firstName + " " + teacher.lastName}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
        <TextField
          value={identifier}
          label={t('CLASS_IDENTIFIER') + " (A, B, C...)"}
          variant="outlined"
          sx={{ width: '100%' }}
          inputProps={{ maxLength: 1 }}
          onChange={(value) => setIdentifier(value.target.value)}
        />
        <Typography variant="caption">Tip: Class identifier represented as different tutorial classes held for a subject class.</Typography>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => academicStore.deleteSubjectClass(targetSubjectClass.id).then(() => {
          rootStore.notify(t('SUBJECT_CLASS') + t('DELETED_B'), 'success');
          onToggleSubjectClassList(academicStore.subjectList.data[0].id);
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_SUBJECT_CLASS')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default SubjectClassIndexPage;
